import React from 'react';
import { Link } from 'gatsby';
import { Layout, Container, Button } from 'components/common';
import { Header } from 'components/theme';
import { Courses } from 'components/landing';
import PostmanGalaxy from '../assets/images/postman-galaxy-2021.png';

const dropboxLink = 'https://www.dropbox.com/s/4vnr5im5n7jkqbm/Postman%20Galaxy%202021.zip?dl=1';

export default () => (
  <Layout>
    <Header />
    <Container>
      <h2>Postman Galaxy 2021</h2>
      <img alt="Postman Galaxy 2021" src={PostmanGalaxy} />
      <h4>Thank you for attending Postman Galaxy 2021 and watching my talk.</h4>
      <Button as={Link} href={dropboxLink}>
        Download slides
      </Button>
      <br />
      <br />
      <br />
    </Container>
    <Courses />
  </Layout>
);
